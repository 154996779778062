//
// Print - User CSS
// --------------------------------------------------

//@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap");

*, .shadow, .shadow-lg, .shadow-sm {
  box-shadow:none!important;
}

body {
  max-width:100vw;
  overflow-x:hidden;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.text-accent,
.text-primary,
.text-muted,
.text-light,
.text-light-muted,
.text-light-80,
.text-module,
.text-book,
.text-course,
.text-discount,
.link-tile-breadcrumb,
.link-tile-icon,
.text-gray-200 {
  color: black !important;
}

h1,
.h1, .h2, .h3, .h4, .h5 {
  font-size: 11pt !important;
  font-weight: bold !important;
  text-align:left;
}

h5,
p, li {
  font-size: 11pt !important;
  color: black;
}



// backgrounds
.bg-course,
.bg-book,
.bg-module,
.bg-tile,
.bg-blue,
.bg-menu,
.bg-primary,
.bg-light {
  background: transparent !important;
}

// font weights
.fw-500 {
  font-weight: 500 !important;
}

// display none
.ci-info,
.link-tile-icon,
.link-delete,
.link-edit,
.link-blue,
.link-light-blue,
.link-muted,
.link-light,
.link-light-muted {
  display: none;
}

// buttons
.btn,
.btn-placeholder,
.btn-delete,
.btn-save {
  display: none !important;
}

// header
header {
  height: 45px;
  margin-bottom: 8px;
  box-shadow: none !important;

  .shadow,
  .shadow-sm,
  .shadow-lg {
    box-shadow: none !important;
  }

  .navbar {
    padding: 0;
    margin: 0;

    .container {
      //padding-left: 0 !important;
      max-width:100%;
      width:100%;

    }

    .input-group {
      display: none !important;
    }
  }
  .navbar-brand {
    width: 6rem !important;
  }
  .topbar,
  .navbar-stuck-menu,
  .navbar-toolbar {
    display: none !important;
  }
}

// footer
footer {
  display: none !important;
}

// padding
.py-5 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

// utility bar
.utility-bar {
  display: none !important;
}

// banner
section[data-widget-name="Banner"] {
  margin: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 6px!important;
  background-color: transparent !important;

  .row {

  }

  h1 {
    margin-bottom: 4px !important;
  }

  h2,
  .text-light-80,
  img {
    display: none;
  }

  .py-5 {
    padding: 8px 0 0 0 !important;
  }
}

// borders
.border-bottom-subtle,
.border-heavy,
.border-bottom-heavy,
.border-field {
  border: solid 1px black;
}

// page titles

.page-title-overlap {

  .container {
    margin:0;
    width:100%;
    max-width:100%;
  }
}

// Progression Maps
.map {

  font-size: 8pt !important;
  font-family: "Roboto Condensed", sans-serif !important;
  color: hsla(212, 91%, 13%, 1) !important;
  line-height: 1.2;
  margin-bottom: 0px !important;

  // map background only
  .map-background-layer {
    display: none !important;
  }

  // map foreground only
  .progression-map {
    margin-bottom: 0px !important;

    .headings-row {
      //height: auto !important;

      .discipline-heading,
      .subcategory {
        border: solid 1px hsla(212, 91%, 13%, 1) !important;
        border-radius: 0 !important;
        background-color: transparent !important;
      }

      .discipline-heading {
        border-right-color: transparent !important;
      }

      .discipline-heading.last-heading {
        border-right-color: hsla(212, 91%, 13%, 1) !important;
      }

      .map-headings {
        padding:6px !important;
        height: calc(38px);
     
        div {
          height: 24px;
          display: -webkit-box;
          overflow:hidden;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

      .map-headings:not(.discipline-heading) {
        border-right-color: transparent !important;
      }
    }

    .subheadings-row {
      height: auto !important;

      .subcategory {
        border: solid 1px hsla(212, 91%, 13%, 1) !important;
        border-top-width: 0px !important;
        border-right-color: transparent !important;

        div {
          height: 24px;
          display: -webkit-box;
          overflow:hidden;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

      }
      .subcategory.last-subcategory {
        border-right-color: hsla(212, 91%, 13%, 1) !important;
      }
    }

    .map-course-row {
      height: auto;
      margin-top: 0;

      .col,
      .map-level-heading,
      .map-course-col,
      .col-1,
      .col-2,
      .col-3,
      .col-4,
      .col-5,
      .col-6,
      .col-7,
      .col-8,
      .col-9,
      .col-10,
      .col-11,
      .col-12 {
        height: auto;

        .card {
          box-shadow: none !important;
          height: auto;
          cursor: pointer;
          border: solid 1px hsla(212, 91%, 13%, 1) !important;

          .card-body {
            box-shadow: none !important;
            background-color: transparent !important;
            
            .card-heading {
              height: 36px;
              display: -webkit-box;
              overflow:hidden;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
    
          
          }



          
        }
      }
    }

    .map-course-row.first-row {
      margin-top: 1rem;
    }

    .map-course-row:last-child {
      border-bottom: solid 1px black !important;
      padding-bottom: 12px !important;
    }

    .map-level:last-child .map-course-row:last-child {
      border-bottom: solid 1px transparent !important;
      padding-bottom: 0px !important;
    }

    // adjusting column-width
    .col-1:not(col-1:first-child) { width: calc((100% - 30px)  / (var(--columns) - 1));}
    .col-2 { width: calc((100% - 30px)  / ((var(--columns) - 1) / 2));}
    .col-3 { width: calc((100% - 30px)  / ((var(--columns) - 1) / 3));}
    .col-4 { width: calc((100% - 30px)  / ((var(--columns) - 1) / 4));}
    .col-5 { width: calc((100% - 30px)  / ((var(--columns) - 1) / 5));}
    .col-6 { width: calc((100% - 30px)  / ((var(--columns) - 1) / 6));}
    .col-7 { width: calc((100% - 30px)  / ((var(--columns) - 1) / 7));}
    .col-8 { width: calc((100% - 30px)  / ((var(--columns) - 1) / 8));}
    .col-9 { width: calc((100% - 30px)  / ((var(--columns) - 1) / 9));}
    .col-10 { width: calc((100% - 30px)  / ((var(--columns) - 1) / 10));}
    .col-11 { width: calc((100% - 30px)  / ((var(--columns) - 1) / 11));}

    // first column - level labels
    .row .col-1:first-child {
      transform: rotate(90deg) !important;
      padding-left: 10px !important;
      width: 30px !important;
      height: 20px !important;
      text-overflow: nowrap !important;
      overflow: visible !important;
    }

    // blank columns at end
    .row .map-headings:not(.discipline-heading):not(:first-child) {
      display: none;
    }
    .bg-specialized,
    .bg-intermediate,
    .bg-foundation,
    .bg-basic {
      background-color: hsla(208, 98%, 20%, 1);
    }
    .bg-map-default {
      background-color: hsla(210, 14%, 92%, 1);
    }
    .bg-map-highlight {
      background-color: hsla(216, 98%, 53%, 0.2);
    }
    .border-transparent {
      border-color: rgba(255, 255, 255, 0)!important;
    }
  }

}


// ecomm
.tns-carousel, .handheld-toolbar, .btn-scroll-top, nav[aria-label="breadcrumb"] {
  display:none;
}

// course pages

.page-title-overlap {
  padding:0!important;

    .container {
      padding-left:0!important;
    }

    .container .row {
      margin:0!important;
      padding:0!important;

      .col-lg-7 {
        width:100%;
        max-width:100%;
      }
    }

    .container .row .text-center {
      display:flex;
      overflow-x:hidden;

      h1 {
        margin-bottom:0!important;
        font-size:12.5pt!important;
      }

      h1 + div {
        text-transform:none!important;
        letter-spacing:0!important;
        word-break:normal;
        word-wrap:normal;
        white-space:nowrap;

      }

      h1 + div::before {
        content:'(';
        display:inline-block;
        margin-left:.25em;
      }
      h1 + div::after {
        content:')';
        display:inline-block;
        margin-right:.25em;
      }
    }

    .container .row .text-end {
      display:none;
    }
}
.page-title-overlap + .container, .course-detail-page {
    padding:0!important;
    margin:0;
    * {
    font-size:11pt!important;
    color:black!important;
  }

  // hide images
  img {
    //display:none!important;
  }

  // hide forms and form fields 
  select {
    display:none;
  }
  
  // body 
  .px-4.pt-lg-3.pb-3.mb-5 {
    padding:0!important;
  }

  .tab-pane .row {
    display:block;
    margin:0!important;
    

    .col-lg-7, .col-lg-5 {
      width:100%;
    }
  }

  .product-details.ms-auto, .product-details {
    margin-left:none!important;
    max-width:100%!important;

    .view {
      display:block!important;
      
      .col {
        padding:0!important;
      }

      .card {
        display:block!important;
        border-width:0px!important;

        .card-body{
          display:flex;
          border-bottom: solid black 1px!important;
          padding-left:0px!important;
          * {
           color:black!important; 
           font-size:11pt!important;
          }

          .info-header {
            display:flex!important;
            margin-right:auto;

            .start-date, .end-date, .location {
              div {
                display:inline-block;
                margin-right:.5em;
              }
            }
            
            .start-date {
              order:1;
            }

            .end-date {
              order:2;
            }

            .end-date::before {
              content:'-';
              display:inline-block;
              margin-right:.25em;
            
            }

            .location {
              order: 4;

              div::before {
                content:'(';
                display:inline-block;
              }
              div::after {
                content:')';
                display:inline-block;
              }
            }

            .type {
              order:3;

              i {
                display:none;
              }
            }

            .type::before {
              content: '|';
              display:inline-block;
              margin-left:.5rem;
              margin-right:.5em;
            }

            .type::after {

                content:'|';
                display:inline-block;
                margin-left:.5rem;
                margin-right:.5em;

            }


            
          }
        }
      }

    }

      // hide accordion
  .mb-5:last-child {
    display:none;
  }
  .accordion {
    display:none;
  }

  }

  // quotes
 blockquote {
   color:black!important;
   margin-bottom:.25em;

 }  


  
}

// course page -- hide related modules
.page-title-overlap + .container ~ .container, .course-detail-page ~ .container {
  display:none;
}


